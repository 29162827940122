
import React, { useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import TechnologyandInnovation from './Pages/Techinnovation/TechnologyandInnovation';
import AccountandFinance from './Pages/Account_Finance/AccountandFinance';
import DigitalMarketing from './Pages/DigitalMarketing_Branding/DigitalMarketing';
import Aboutus from './Pages/AboutUs/Aboutus';
import Contactus from './Pages/Contactus/Contactus'
import Home from './Pages/Home/Home';
import FinTech from './Pages/FinTech/FinTech';
import IYConnect from './Pages/Home/IYConnect';
import Privacy from './Pages/Home/privacy-policy';
import BusinessSupport from './Pages/BusinessSupport/BusinessSupportConsultation';
import BusinessPersonalFinance from './Pages/BusinessPersonal/BusinessFinace';
import ScrollToTop from './Pages/ScrollToTop'
import Blog from './Pages/Blog/Blog';
import SingleBlogPost from './Pages/Blog/SingleBlogPost';
import Login from './Pages/Blog/Login';
import AdminPanel from "./Pages/Blog/AdminPanel";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeletePage from './Pages/Blog/deletePage';
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  return (
    <BrowserRouter>
    <ScrollToTop/>
    <ToastContainer/>
      <Routes>
        
        <Route exact path="/" element={<Home />} />
        <Route path="/Aboutus" element={<Aboutus />} />
        <Route path="/Contactus" element={<Contactus />} />
        <Route path="/TechnologyandInnovation" element={<TechnologyandInnovation />} />
        <Route path="/AccountandFinance" element={<AccountandFinance />} />
        <Route path="/DigitalMarketing" element={<DigitalMarketing />} />
        <Route path="/Fintech" element={<FinTech />} />
        <Route path="/IYConnect" element={<IYConnect />} />
        <Route path="/BusinessSupport" element={<BusinessSupport />} />
        <Route path="/BusinessPersonalFinance" element={<BusinessPersonalFinance />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/Blog"
         element={<Blog/>}/>
        <Route path="/Login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />

{/* Protected route for AdminPanel */}
<Route
  path="/AdminPanel"
  element={isLoggedIn ? <AdminPanel /> : <Navigate to="/Login" />}
/>
      
          
          {/* Route for the PostList component */}
          
          <Route path="/AdminPanel" element={<AdminPanel />} />

        
          {/* Route for the PostList component */}
        <Route path='/singlePost/:id' element={<SingleBlogPost/>}/>
          <Route path="/AdminPanel" element={<AdminPanel />} />
        <Route path="/DeletePage" element={<DeletePage/>}/>
      </Routes>
    </BrowserRouter>
  
  );
}

export default App;