import React from "react";
import "./FinTech.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import SendMessage from "../SendMessaage/SendMessage";
import { Helmet } from "react-helmet";
import video from "../Video/Tech.mp4";
const Fintech = () => {
  const pageId=8
  return (
    
    <>
    <Helmet>
    <script type="application/ld+json">
        {`{
"@context" : "http://schema.org",
"@type" : "WebSite", 
"name" : "IYKONS",
"url" : "https://www.iykons.com/",
"potentialAction" : {
    "@type" : "SearchAction",
    "target" : "https://www.iykons.com/search?q={search_term}",
    "query-input" : "required name=search_term"
  }
}
  `}
</script>  

    <script type="application/ld+json">
          {`
          {
              "@context": "http://schema.org",
              "@type": "Organization",
              "name": "Iykons Business Services",
              "url": "https://www.iykons.com/",
              "logo": "https://www.iykons.com/static/media/Logo.10cd63fcf794bdb64f91.png",
              "contactPoint": {
                "@type": "ContactPoint",
                "contactType": "customer support",
                "email": "info@iykons.com",
                "url": "/"
              },
              "sameAs": [
                "https://twitter.com/jey_raj",
                "https://www.facebook.com/iykons/",
                "https://www.tiktok.com/@iykons.uk?lang=en",
                "https://www.linkedin.com/company/iykons-ltd/",
                "https://www.instagram.com/iykon.uk/"
              ]
          }
          `}
        </script>
        {/* Google Tag Manager script */}
        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-W79GB927');
          `}
        </script>

        {/* Google Tag Manager noscript */}
        <noscript>
          {`
          <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W79GB927"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>
          `}
        </noscript>
        <title>IT Service Providers for Fintech Companies in India | Fintech</title>
<meta name="description" content="Explore Iykons for innovative fintech solutions. Elevate your financial services with cutting-edge technology and expertise."/>
<link rel="canonical" href="https://iykons.com/Fintech"/>
      </Helmet>
    <Header/>
      <section className="imagerow1"style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}>
      <div className="page-head1 header-text">
          <video autoPlay muted loop id="bg-video"className="video-with-shadow">
            <source src={video} type="video/mp4" />
            {/* Add additional source elements for other video formats */}
            Your browser does not support the video tag.
          </video>
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <h1>IYKONS Fintech</h1>
                <span>
                In today's ever-evolving landscape, FinTech has revolutionized the financial industry by introducing alternative services that leverage smart technologies. At IYKONS, we are at the forefront of this digital transformation, offering a comprehensive suite of FinTech solutions to meet the diverse needs of businesses and individuals. 
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      
      <h1 style={{textAlign:'center',marginTop:'30px'}}>Digital Dashboard Services: Your Financial Control Center</h1>
      <div class="container">
          <div className="d-flex mt-3 service-alt">
            <div>
              <span className="bi-pie-chart-fill me-4"></span>
            </div>
            <div>
              <h3>Digital Wallet:</h3>
              <p>
              Our digital wallet is your gateway to securely storing and managing digital versions of your funds within the digital ecosystem. Your money, your control.
              </p>
            </div>
          </div>

          <div className="d-flex mb-3 service-alt">
            <div>
              <span className="bi-pie-chart-fill me-4"></span>
            </div>
            <div>
              <h3>Digital Payments:</h3>
              <p>
              Experience a seamless payment journey with IYKONS. Say goodbye to payment hassles and hello to effortless transactions.
              </p>
            </div>
          </div>

          <div className="d-flex mb-3 service-alt">
            <div>
              <span className="bi-pie-chart-fill me-4"></span>
            </div>
            <div>
              <h3>Promotions & offers:</h3>
              <p>
              Explore a world of savings with our promotions and offers. From digital coupons to cashback, referral programs, and reward initiatives, we're here to help you save while you spend.
              </p>
            </div>
          </div>

          <div className="d-flex mb-3 service-alt">
            <div>
              <span className="bi-pie-chart-fill me-4"></span>
            </div>
            <div>
              <h3>Utility Bill Payments:</h3>
              <p>
              Pay your utility bills hassle-free, whether it's electricity, water, taxes, or cross-border payments. We've got you covered.


              </p>
            </div>
          </div>
          <div className="d-flex mb-3 service-alt">
            <div>
              <span className="bi-pie-chart-fill me-4"></span>
            </div>
            <div>
              <h3>Forex & Routing:</h3>
              <p>
              Our forex and routing services are designed for your convenience. With automated journeys and overriding features, managing your forex transactions has never been easier.</p>
            </div>
          </div>
          <div className="d-flex mb-3 service-alt">
            <div>
              <span className="bi-pie-chart-fill me-4"></span>
            </div>
            <div>
              <h3>BLOCKCHAIN: Leveraging Cutting-Edge Technology</h3>
              <p>
              IYKONS embraces the power of blockchain and cutting-edge technologies to provide you with scalable and efficient solutions based on tested hypotheses.

</p>
            </div>
          </div>
          <div className="d-flex mb-3 service-alt">
            <div>
              <span className="bi-pie-chart-fill me-4"></span>
            </div>
            <div>
              <h3>Cross-Border Remittances</h3>
              <p>
              Experience the ease of cross-border money transfers with IYKONS. From bank-to-bank transfers to card-to-account transactions, card-to-card or cash transfers, wallet transfers, and airtime top-ups, we offer a comprehensive solution.

</p>
            </div>
          </div>
    <h1 style={{textAlign:'center',marginTop:'30px'}}>IYKONS Fintech: Key Features</h1>
          <div className="d-flex mt-3 service-alt">
            <div>
              <span className="bi-pie-chart-fill me-4"></span>
            </div>
            <div>
              <h3>Digital Onboarding: </h3>
              <p>
              Our customer onboarding journey is seamlessly integrated with third-party AML/KYC provider APIs, ensuring a smooth and secure registration process.
              </p>
            </div>
          </div>

          <div className="d-flex mb-3 service-alt">
            <div>
              <span className="bi-pie-chart-fill me-4"></span>
            </div>
            <div>
              <h3>AML Monitoring: </h3>
              <p>
              Count on our pre-built rules and alerts to detect fraud, suspicious cases, and more. We've got your security covered.
              </p>
            </div>
          </div>

          <div className="d-flex mb-3 service-alt">
            <div>
              <span className="bi-pie-chart-fill me-4"></span>
            </div>
            <div>
              <h3>Risk Metrics: </h3>
              <p>
              Our risk monitoring matrix and assessment solutions protect your business, both before and after customer onboarding.
              </p>
            </div>
          </div>

          <div className="d-flex mb-3 service-alt">
            <div>
              <span className="bi-pie-chart-fill me-4"></span>
            </div>
            <div>
              <h3>24/7 Functional Support:</h3>
              <p>
              With IYKONS, you have access to 360-degree, 24/7 functional support. We're here whenever you need us.


              </p>
            </div>
          </div>
          <div className="d-flex mb-3 service-alt">
            <div>
              <span className="bi-pie-chart-fill me-4"></span>
            </div>
            <div>
              <h3>Compliance: </h3>
              <p>
              Stay fully compliant with our pre-set journey and receive 24/7 alerts for your backend teams, all in accordance with regulatory policies tailored to your specific country..</p>
            </div>
          </div>
         
         </div>
         
        
         <SendMessage pageId={pageId}/>
      <Footer />
    </>
  );
};

export default Fintech;
