import React from "react";
import "../css/AccountingandFinace.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import about_1 from "../image/image/prepareacccount.jpg";
import about_2 from "../image/image/Bookkeeping.jpg";
import about_3 from "../image/image/financialproje.jpg";
import about_4 from "../image/image/selfevaluation.jpg";
import about_5 from "../image/image/VAAT.jpg";
import about_6 from "../image/image/PAYEandCIS.jpg";
import about_7 from "../image/image/Pension.jpg";
import about_77 from "../image/image/WebDeisgn.jpg";
import about_8 from "../image/image/CorporateIncomeTax.jpg";
import about_9 from "../image/image/MTD.jpg";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import SendMessage from "../SendMessaage/SendMessage";
import video from "../Video/Account.mp4";
import { Helmet } from "react-helmet";
export default function AccountandFinance() {
  const pageId=2;
  return (
    <>
    <Helmet>
    <script type="application/ld+json">
        {`{
"@context" : "http://schema.org",
"@type" : "WebSite", 
"name" : "IYKONS",
"url" : "https://www.iykons.com/",
"potentialAction" : {
    "@type" : "SearchAction",
    "target" : "https://www.iykons.com/search?q={search_term}",
    "query-input" : "required name=search_term"
  }
}
  `}
</script>  

    <script type="application/ld+json">
          {`
          {
              "@context": "http://schema.org",
              "@type": "Organization",
              "name": "Iykons Business Services",
              "url": "https://www.iykons.com/",
              "logo": "https://www.iykons.com/static/media/Logo.10cd63fcf794bdb64f91.png",
              "contactPoint": {
                "@type": "ContactPoint",
                "contactType": "customer support",
                "email": "info@iykons.com",
                "url": "/"
              },
              "sameAs": [
                "https://twitter.com/jey_raj",
                "https://www.facebook.com/iykons/",
                "https://www.tiktok.com/@iykons.uk?lang=en",
                "https://www.linkedin.com/company/iykons-ltd/",
                "https://www.instagram.com/iykon.uk/"
              ]
          }
          `}
        </script>
        {/* Google Tag Manager script */}
        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-W79GB927');
          `}
        </script>

        {/* Google Tag Manager noscript */}
        <noscript>
          {`
          <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W79GB927"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>
          `}
        </noscript>
        <title>Account & Finance Solutions | IT Service Providers in India – Iykons</title>
<meta name="description" content="Discover tailored account & finance solutions and IT services for Indian fintech. Partner with Iykons, a leading Chennai-based IT provider, for comprehensive HR and fintech development."/>
<link rel="canonical" href="https://iykons.com/AccountandFinance"/>
      </Helmet>
      <Header />
      <section className="imagerow" style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}>
        <div className="page-head1 header-text">
          <video autoPlay muted loop id="bg-video">
            <source src={video} type="video/mp4" />
            {/* Add additional source elements for other video formats */}
            Your browser does not support the video tag.
          </video>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>Accounting & Finance</h1>
                <span>
                At IYKONS, Our Accounting & Finance service offers a range of tailored services to meet your business's financial needs. We understand the importance of accuracy, efficiency, and compliance, and our team is committed to being your trusted partner in managing your financial resources effectively. With our expertise, we provide comprehensive solutions designed to optimize your financial performance.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="perant">
        <div className="body">
          <div class="container1">
            <div class="card">
              <div class="imgBx">
                <img src={about_1} alt="image 1" />
              </div>
              <div class="content">
                <h2>Preparation of Accounts</h2>
                <p style={{textAlign:'justify',fontSize:14.3}}>
                  Your company’s annual accounts, also known as statutory
                  accounts, are prepared at the conclusion of the fiscal year
                  using the company’s financial records. We can support you
                  through this whole process to ensure all procedures are
                  followed in line with HMRC guidelines.
                </p>
              </div>
            </div>
            <div class="card">
              <div class="imgBx">
                <img src={about_2} alt="image 2" />
              </div>
              <div class="content">
                <h2>Bookkeeping</h2>
                <p style={{textAlign:'justify',fontSize:14.3}}>
                Our specialised bookkeeping team will assist you every step of the way, ensuring the systematic recording and organization of your company’s financial activity to guarantee the accuracy of all transactions.</p>
                <p style={{textAlign:'center'}}>“We at IYKONS cater to bookkeeping for small businesses and mid-size enterprises"</p>
              </div>
            </div>
            <div class="card">
              <div class="imgBx">
                <img src={about_3} alt="image 3" />
              </div>
              <div class="content">
                <h2>Financial Projection</h2>
                <p style={{textAlign:'justify',fontSize:14.3}}>
                  A financial forecast is an estimate of a company’s future
                  financial outcomes and is an essential aspect of the annual
                  budget process. Please contact our helpful team to obtain a
                  financial estimate for your company.
                </p>
              </div>
            </div>

            <div class="card">
              <div class="imgBx">
                <img src={about_4} alt="image 4" />
              </div>
              <div class="content">
                <h2>Self Evaluation</h2>
                <p style={{textAlign:'justify',fontSize:14.3}}>
                HMRC uses self evaluation to gather Income Tax, with automatic taxation for wages, pensions, and savings. Additional earnings, like COVID-19 support, need reporting via tax returns. Simplify the process by partnering with IYKONS for seamless self-evaluation submission, ensuring proper procedures.
                </p>
              </div>
            </div>
            <div class="card">
              <div class="imgBx">
                <img src={about_5} alt="image 5" />
              </div>
              <div class="content">
                <h2>VAT</h2>
                <p style={{textAlign:'justify',fontSize:14.3}}>
                  The difference between your sales and purchase invoices is
                  usually the amount of VAT you pay to HM Revenue and Customs
                  (HMRC). Even if invoices have not been paid, you must record
                  this data and pay what you owe to HMRC. IYKONS handles all
                  aspects of VAT accounting.
                </p>
              </div>
            </div>
            <div class="card">
              <div class="imgBx">
                <img src={about_6} alt="image 6" />
              </div>
              <div class="content">
                <h2>PAYE and CIS</h2>
                <p style={{textAlign:'justify',fontSize:14.3}}>
                PAYE is the tax payment method for most employees, while the Construction Industry Scheme (CIS) combats fraud and safeguards construction workers. Contractors withhold 20% of subcontractors' pay for tax and National Insurance. Compliance with PAYE and CIS is crucial before commencing work.
                </p>
              </div>
            </div>
            <div class="card">
              <div class="imgBx">
                <img src={about_7} alt="image 7" />
              </div>
              <div class="content">
                <h2>Pension</h2>
                <p style={{textAlign:'justify',fontSize:14.3}}>
                  Our team of experts will explain every detail of the pension
                  system to you. Furthermore, we also assist with establishing
                  NEST or other private pension accounts and saving for the
                  future in a tax-efficient manner.
                </p>
              </div>
            </div>
            <div class="card">
              <div class="imgBx">
                <img src={about_8} alt="image 8" />
              </div>
              <div class="content">
                <h2>Corporate Income Tax</h2>
                <p style={{textAlign:'justify',fontSize:14.3}}>
                  Corporate tax is a tax on profits made by a limited company.
                  The HMRC does not send you an automatic bill for Corporation
                  Tax. You must instead pay and declare your tax. We help you
                  navigate this complex system smoothly and ensure that you are
                  always tax-ready.
                </p>
              </div>
            </div>
            <div class="card">
              <div class="imgBx">
                <img src={about_9} alt="image 9" />
              </div>
              <div class="content">
                <h2>MTD – Making Tax Digital</h2>
                <p style={{textAlign:'justify',fontSize:14.3}}>
                Making Tax Digital (MTD) simplifies tax filing for individuals and businesses. IYKONS, a digital accounting leader, readies clients for MTD changes, offering proactive assistance. Contact us to prepare effectively for the MTD transition
                </p>
              </div>
              </div>
              <div class="card">
              <div class="imgBx">
              <img src={about_77} alt="image 7" />
              </div>
              <div class="content">
                <h2>Account Management</h2>
                <p style={{textAlign:'justify',fontSize:14.3}}>
                Management accounting is a crucial function that provides valuable insights into a company's performance and helps decision-makers make strategic choices. It involves identifying, analyzing, and communicating financial information at various intervals to support the business in achieving its goals.


                </p>
              </div>
              </div>
            </div>
          </div>
        </div>
      
        <SendMessage pageId={pageId}/>
      <Footer />
    </>
  );
}
