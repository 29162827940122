import React, { useRef } from 'react'
import emailjs from '@emailjs/browser'
import "../css/Contactpage.css"
import { Helmet } from "react-helmet";
import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import { toast } from "react-toastify";
export default function Contactus() {
  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs.sendForm('service_54ucz8j', 'template_mrc29tz', form.current, '1XLXownNy7vZxNN8g')
      .then((result) => {
          console.log(result.text)
          saveDataToServer()
          form.current.reset()
          toast.success('Details sent successfully');
      }, (error) => {
        alert("Message Not Send")
          console.log(error.text)
      });
    };
    const saveDataToServer = async () => {
      const formData = new FormData(form.current);
      const jsonData = {
          name: formData.get('name'),
          email: formData.get('email'),
          subject: formData.get('subject'),
          message: formData.get('message'),
      };
  
      try {
          const response = await axios.post('https://localhost:5098/api/ContactUs', jsonData, {
              headers: {
                  'Content-Type': 'application/json',
              },
          });
  
          if (response.status === 200) {
              toast.success('Details sent successfully');
          } else {
              toast.error('Failed to send Details');
          }
      } catch (error) {
          console.error('Error:', error);
      }
  };

  return (
    <>
    <Helmet>
   
    <script type="application/ld+json">
          {`
          {
              "@context": "http://schema.org",
              "@type": "Organization",
              "name": "Iykons Business Services",
              "url": "https://www.iykons.com/",
              "logo": "https://www.iykons.com/static/media/Logo.10cd63fcf794bdb64f91.png",
              "contactPoint": {
                "@type": "ContactPoint",
                "contactType": "customer support",
                "email": "info@iykons.com",
                "url": "/"
              },
              "sameAs": [
                "https://twitter.com/jey_raj",
                "https://www.facebook.com/iykons/",
                "https://www.tiktok.com/@iykons.uk?lang=en",
                "https://www.linkedin.com/company/iykons-ltd/",
                "https://www.instagram.com/iykon.uk/"
              ]
          }
          `}
        </script>
        <script type="application/ld+json">
{`
  {
  "@context": "http://schema.org",
  "@type": "WebSite",
  "name": "IYKONS",
  "url": "https://www.iykons.com/",
  "potentialAction": {
    "@type": "SearchAction",
    "target": "https://www.iykons.com/search?q={search_term}",
    "query-input": "required name=search_term"
  }
}
`}
</script>
        {/* Google Tag Manager script */}
        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-W79GB927');
          `}
        </script>

        {/* Google Tag Manager noscript */}
        <noscript>
          {`
          <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W79GB927"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>
          `}
        </noscript>
        <title>Contact Iykons for Expert Business Solutions | Iykons</title>
<meta name="description" content="Get in touch with Iykons for tailored business solutions. Reach out today to discuss your needs with our experienced team."/>
<link rel="canonical" href="https://iykons.com/Contactus"/>
      </Helmet>
      <Header />
      <section id="contact" className="contact">
        <div className="container3" data-aos="fade-up" data-aos-delay="100">
         
          <div className=" container8 row gt-4 mt-3">
            <h1>Contact us</h1>
            <div className="col-lg-12">
              <form
                ref={form}
                onSubmit={sendEmail}
                role="form"
                className="php-email-form"
              >
                <div className="row gy-4">
                  <div className="col-lg-6 form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control form-control-lg"
                      id="name"
                      placeholder="Your Name"
                      required
                    />
                  </div>
                  <div className="col-lg-6 form-group">
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    name="subject"
                    id="subject"
                    placeholder="Subject"
                    required
                  />
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control "
                    name="message"
                    rows="5"
                    placeholder="Message"
                    required
                  ></textarea>
                </div>
                <div className="text-center">
                  <button type="submit">Send Message</button>
                </div>
              </form>
            </div>
          </div>

          <div>
           <table class="table">
  <tr>
    <th>Location</th>
    <th>Address</th>
    <th>Email</th>
  </tr>
  
  <tr>
    <td>LONDON</td>
    <td>
      <FontAwesomeIcon icon={faMapMarker} /> IYKONS
392 Ewell Road 
Tolworth 
Surrey 
KT67BB
    </td>
    <td>
      <FontAwesomeIcon icon={faEnvelope} /> info@iykons.com
    </td>
  </tr>
  <tr>
    <td>SYDNEY</td>
    <td>
      <FontAwesomeIcon icon={faMapMarker} /> PO box 529, Wentworthville, NSW 2145, Australia.
    </td>
    <td>
      <FontAwesomeIcon icon={faEnvelope} /> info@iykons.com
    </td>
  </tr>
  <tr>
  <td>CHENNAI</td>
    <td>
      <FontAwesomeIcon icon={faMapMarker} /> No 138, II Floor, Ameen Manor, Nungambakkam High Road, Nungambakkam, Chennai – 600034.
    </td>
    <td>
      <FontAwesomeIcon icon={faEnvelope} /> info@iykons.com
    </td>
  </tr>
  <tr>
    <td>VAVUNIYA</td>
    <td>
      <FontAwesomeIcon icon={faMapMarker} /> 45 A , Jaffna Road . Vavuniya
    </td>
    <td>
      <FontAwesomeIcon icon={faEnvelope} /> info@iykons.com
    </td>
  </tr>
</table>
</div>


     <div className="mapx row gy-4">
            <div className="col-lg-3">
              {/* London */}
              <iframe
                src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=14,%20Elm%20Road,%20Chessington,%20Surrey,%20KT9%201AW,%20United%20Kingdom.+(IYKONS)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                frameBorder="0"
                style={{ border: 0, width: '100%', height: '300px' }}
                allowFullScreen
              ></iframe>
              
            </div>
            <div className="col-lg-3">
              {/* India */}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.702577427123!2d80.2500644!3d13.0545941!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526747401de1a5%3A0x836a8cd352fbd5c2!2sIykons%20Business%20Services%20India%20Private%20Limited!5e0!3m2!1sen!2slk!4v1692883023834!5m2!1sen!2slk"
                style={{ border: 0, width: '100%', height: '300px' }}
                allowFullScreen
              ></iframe>
            </div>
            <div className="col-lg-3">
              {/* Australia */}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.519052599945!2d151.10937877443604!3d-33.87628366024956!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12bb526a150069%3A0x32a3bbe2655fc652!2sIykons%20Australia!5e0!3m2!1sen!2slk!4v1692883452597!5m2!1sen!2slk"
                style={{ border: 0, width: '100%', height: '300px' }}
                allowFullScreen
              ></iframe>
            </div>
            <div className="col-lg-3">
              {/* Sri Lanka */}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3943.2503774465044!2d80.49453647501574!3d8.762497691288607!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOMKwNDUnNDUuMCJOIDgwwrAyOSc0OS42IkU!5e0!3m2!1sen!2slk!4v1696157545287!5m2!1sen!2slk"
                style={{ border: 0, width: '100%', height: '300px' }}
                allowFullScreen
              ></iframe>
            </div>
          </div>
        
     </div>
  
  


 
        
        
        
      </section>
      <Footer />
    </>
  );
}
