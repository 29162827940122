import React from "react";
import "../css/DigitalMarketing.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import about_1 from "../image/image/SearchEngineOptimization.jpg";
import about_2 from "../image/image/SearchEngineMarketing.jpg";
import about_3 from "../image/image/SocialMediaMarketing.jpg";
import about_4 from "../image/image/ConversionRateOptimization.jpg";
import about_5 from "../image/image/OnlineReputationManagement.jpg";
import about_6 from "../image/image/per.jpeg";
import about_7 from "../image/image/WebDeisgn.jpg";
import about_8 from "../image/image/Webdevelopment.jpg";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import SendMessage from "../SendMessaage/SendMessage";
import video from "../Video/DgitalMarket.mp4";
import { Helmet } from "react-helmet";
<link
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"
  rel="stylesheet"
  integrity="sha384-KyZXEAg3QhqLMpG8r+Ikmj3Ujs/b6kHgq7t4z4f+owz6Wks2N54lFbcg4CpBOhFfA"
  crossorigin="anonymous"
></link>
export default function DigitalMarketing() {
  const pageId=3;
  return (
    <>
    <Helmet>
    <script type="application/ld+json">
        {`{
"@context" : "http://schema.org",
"@type" : "WebSite", 
"name" : "IYKONS",
"url" : "https://www.iykons.com/",
"potentialAction" : {
    "@type" : "SearchAction",
    "target" : "https://www.iykons.com/search?q={search_term}",
    "query-input" : "required name=search_term"
  }
}
  `}
</script> 

    <script type="application/ld+json">
          {`
          {
              "@context": "http://schema.org",
              "@type": "Organization",
              "name": "Iykons Business Services",
              "url": "https://www.iykons.com/",
              "logo": "https://www.iykons.com/static/media/Logo.10cd63fcf794bdb64f91.png",
              "contactPoint": {
                "@type": "ContactPoint",
                "contactType": "customer support",
                "email": "info@iykons.com",
                "url": "/"
              },
              "sameAs": [
                "https://twitter.com/jey_raj",
                "https://www.facebook.com/iykons/",
                "https://www.tiktok.com/@iykons.uk?lang=en",
                "https://www.linkedin.com/company/iykons-ltd/",
                "https://www.instagram.com/iykon.uk/"
              ]
          }
          `}
        </script>
        {/* Google Tag Manager script */}
        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-W79GB927');
          `}
        </script>

        {/* Google Tag Manager noscript */}
        <noscript>
          {`
          <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W79GB927"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>
          `}
        </noscript>
        <title>Best Digital Marketing Agencies in UK | Iykons</title>
<meta name="description" content="Elevate your online presence with Iykons, the best digital marketing company in the UK. Trusted by businesses nationwide for unparalleled results."/>
<link rel="canonical" href="https://iykons.com/DigitalMarketing"/>
      </Helmet>
      <Header />
      <section className="imagerow" style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}>
        <div className="page-head header-text">
          <video autoPlay muted loop id="bg-video"className="video-with-shadow">
            <source src={video} type="video/mp4" />
            {/* Add additional source elements for other video formats */}
            
          </video>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
              <h1 className="text-center" style={{ color: 'white' }}>Digital Marketing & Branding</h1>

              <span>
              Iykons' Digital Marketing Services, where our innovative solutions empower businesses to thrive in the online realm. With a sharp focus on driving visibility, engagement, and growth, we specialize from WEB DEVELOPMENT, SOCIAL MEDIA, SEO, and SEM to Email Marketing services to optimize your brand portfolio for higher rankings and conversions.
</span><br/>
<span>
Ready to level up your Online Presence? Let's chat!</span>

                
              </div>
            </div>
          </div>
        </div>
      </section>


      <div className="perant">
        <div className="body">
          <div class="container1">
            <div class="card">
              <div class="imgBx">
                <img src={about_1} alt="image 1" />
              </div>
              <div class="content">
                <h2 className="h2">Search Engine Optimization</h2>
                <p style={{textAlign:'center',fontSize:14}}>
                Our dynamic SEO campaigns blend captivating content and off-page mastery, propelling your site into the search spotlight. Elevate your online game with our SEO packages—let's transform your website into a digital sensation. Join us and conquer the search realm today!
                </p>
              </div>
            </div>
            <div class="card">
              <div class="imgBx">
                <img src={about_2} alt="image 2" />
              </div>
              <div class="content">
                <h2 className="h2">Search Engine Marketing</h2>
                <p style={{textAlign:'center',fontSize:14}}>
                Our search engine marketing services turn your brand into an online sensation, outshining competitors. Let's make digital dreams a reality. Join us and soar with SEM magic!
                </p>
              </div>
            </div>
            <div class="card">
              <div class="imgBx">
                <img src={about_3} alt="image 3" />
              </div>
              <div class="content">
                <h2 className="h2">Social Media Marketing</h2>
                <p style={{textAlign:'center',fontSize:14}}>
                We're not your average social media agency; we're your partners in digital success!
As a dedicated social media agency for small businesses, we specialize in crafting tailored strategies to elevate your online presence, engage your target audience, and drive measurable results.

                </p>
              </div>
            </div>

            <div class="card">
              <div class="imgBx">
                <img src={about_4} alt="image 4" />
              </div>
              <div class="content">
                <h2 className="h2">Conversion Rate Optimization</h2>
                <p style={{textAlign:'center',fontSize:14}}>
                  We uncover obstacles in the user experience that may be
                  hindering additional conversions through user research. Make
                  the most of your current website traffic with our services.
                </p>
              </div>
            </div>
            <div class="card">
              <div class="imgBx">
                <img src={about_5} alt="image 5" />
              </div>
              <div class="content">
                <h2 className="h2">Online Reputation Management</h2>
                <p style={{textAlign:'center',fontSize:14}}>
                  Whether it is an Enterprise, Corporate, or SME, a poor
                  reputation has a negative impact on revenue and brand. We have
                  assisted businesses of various sizes in building, maintaining
                  and defending their reputations and responding to claims.
                </p>
              </div>
            </div>
            <div class="card">
              <div class="imgBx">
                <img src={about_6} alt="image 6" />
              </div>
              <div class="content">
                <h2 className="h2">Pay Per Click </h2>
                <p style={{textAlign:'center',fontSize:14}}>
                  Whatever the size or sector of your business, we can advise
                  you on the activities and tasks you need to complete to help
                  you achieve your objectives.
                </p>
              </div>
            </div>
            <div class="card">
              <div class="imgBx">
                <img src={about_7} alt="image 7" />
              </div>
              <div class="content">
                <h2 className="h2">Website Design</h2>
                <p style={{textAlign:'center',fontSize:14}}>
                  A website is one of your organisation’s most significant
                  digital channels, so it must function well, look attractive,
                  and, most importantly, be indexable. Your website requires an
                  on-brand design that is customised to support and enhance the
                  customer journey. We can help you maximise the return from
                  your website.
                </p>
              </div>
            </div>

            <div class="card">
              <div class="imgBx">
                <img src={about_8} alt="image 8" />
              </div>
              <div class="content">
                <h2 className="h2">Web Development</h2>
                <p style={{textAlign:'center',fontSize:14}}>
                  Our web experts have vast experience designing online
                  applications using a wide range of web frameworks, programming
                  languages, and protocols. We can provide a one-stop shop to
                  handle all of your web development needs
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SendMessage pageId={pageId}/>
      <Footer />
    </>
  );
}
