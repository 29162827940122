import React from "react";
//import "../css/Privacy.css";
import Footer from "../Footer/Footer";

export default function Privacy() {
  return (
    <>
     <div style={{ lineHeight: '2.5' }}id="privacy-policy">
      <section id="skills">
        <div className="container">
          <p>
            <h1 style={{fontSize: "24px", fontWeight: "bold"}}>PRIVACY POLICY </h1>     
          </p>

          <p>
              <strong><u>This is the privacy notice of IYKONS Limited and other members of the IYKONS Group.</u></strong>
          </p>

<p>
We respect your privacy and are determined to protect your personal data.The purpose of this privacy notice is to inform you how we look after your personal data when you visit our website (regardless of where you visit it from). We’ll also tell you about your privacy rights and how the <a href="https://www.gov.uk/government/publications/data-protection-act-2018-overview"> United Kingdom Data Protection Act of 2018</a> protects you.You agree that all matters relating to access or use of our website, including all disputes, will be governed by English law.
</p>
<p>
This Privacy Policy does not apply to personal information that we collect about IYKONS employees, contractors, applicants, or candidates.This privacy notice is provided in a layered format so you can click through to the specific areas set out below. Alternatively, you can download a PDF version of the policy 
 <a href="https://web.archive.org/web/20220625024142/https://www.iykons.com/Document/IYKONS_Privacy_Policy.pdf"> Click Here
            </a>
          </p>
             <br/>  


          <h1 style={{fontSize: "24px", fontWeight: "bold"}}>1.Who We Are and Important Information</h1>
          <p>
              <strong><u>What is the purpose of this privacy notice?</u></strong>
          </p>
          <p>
          This privacy notice aims to give you information on how we collect and process your personal data through your use of this website, including any data you may provide when you sign up for our newsletter, purchase a product or service, or register for our services.
          </p>

            <p>
            This website is not intended for children, and we do not knowingly collect data relating to children.
            </p>

            <p>
            You must read this privacy notice together with any other privacy notice we may provide on specific occasions when we are collecting or processing personal data about you, so that you are fully aware of how and why we are using your data.
            </p>

        
         <p>
              <strong><u> Data Controller(s)</u></strong>
          </p>

            <p>
            IYKONS LIMITED is the controller and responsible for your personal data (collectively referred to as “COMPANY,” “we,” “us,” or “our” in this privacy notice).
            </p>

            <p>Our contact details are:
              <br/>Address: 14 Elm Road, Chessington, Surrey, KT9 1AW, United Kingdom.
              <br/>Email:<a href="mailto:info@iykons.com"> info@iykons.com</a>
              <br/>Telephone: <a href="tel:+44 20 3598 2904">+44 20 3598 2904</a>
              <br/>For all data matters contact DATA PROTECTION OFFICER on <a href="mailto:data@iykons.com">DATA@IYKONS.COM</a>
            </p>
       

            <p>
              <strong><u> Third-party links outside of our control</u></strong>
          </p>

            <p>
              This website may include links to third-party websites, plug-ins,
              and applications. Clicking on those links or enabling those
              connections may allow third parties to collect or share data about
              you. We do not control these third-party websites and are not
              responsible for their privacy statements.
            </p>
            <p>
              When you leave our website, we encourage you to read the privacy
              notice of every website you visit.
            </p>
          

            <br/>
          <h1 style={{fontSize: "24px", fontWeight: "bold"}}>2.The Personal Data We Collect About You</h1>
<p>
Personal data means any information about an individual from which that person can be identified. We may collect, use, store, and transfer different kinds of personal data about you, which we have grouped together as follows:
</p>
            <p>
              <strong>Identity Data:</strong> First name, last name, username, marital status, title, date of birth, gender.
Contact Data: Billing address, delivery/postal address, email address, telephone numbers.
We also collect, use, and share aggregated data such as statistical or demographic data for any purpose. Aggregated data may be derived from your personal data but is not considered personal data in law, as this data does not directly or indirectly reveal your identity.
            </p>
            <p>
              We do not collect any <strong>Special Categories of Personal Data</strong> about
              you (this includes details about your race or ethnicity, religious
              or philosophical beliefs, sex life, sexual orientation, political
              opinions, trade union membership, information about your health
              and genetic and biometric data). Nor do we collect any information
              about criminal convictions and offences.
            </p>

            <p>
              <strong><u>If you fail to provide personal data</u></strong>
            </p>

            <p>
              Where we need to collect your personal data by law, or under the
              terms of a contract we have with you and you fail to provide that
              data when requested,<strong> we may not be able to perform the contract</strong> we
              have or are trying to enter into with you (for example, to provide
              you with goods or services). In this case, we may have to cancel a
              product or service you have with us but we will notify you if this
              is the case at the time.
            </p>
            <br/>
          <h1 style={{fontSize: "24px", fontWeight: "bold"}}>3. How We Collect Your Personal Data</h1>

<p>We use different methods to collect data from and about you including through:</p>
          
           <p><ul>
        <li>
          <strong>Direct interactions: </strong>You may give us your identity, contact, and financial data by filling in forms or by corresponding with us by post, phone, email, or otherwise. This includes personal data you provide when you:
          <ul>
            <li>Apply for our products or services;</li>
            <li>Create an account on our website;</li>
            <li>Subscribe to our service or publications;</li>
            <li>Request marketing to be sent to you.</li>
          </ul>
        </li>
      </ul></p>
      <br/>
      <h1 style={{fontSize: "24px", fontWeight: "bold"}}>4. How We Use Your Personal Data</h1> 
            <p>
              We will only use your personal data when the law allows us to.
              Most commonly, we will use your personal data in the following
              circumstances:
            </p>
            <p>
              <strong>Performance of Contract:</strong> Processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.
            </p>
            <p>
              <strong>Legitimate Interest:</strong> The interest of our business in conducting and managing our business to enable us to provide you with the best service/product and the most secure experience.
            </p>
            <p>
              <strong>Comply with a Legal or Regulatory Obligation: </strong>{" "}
              Processing your personal data where it is necessary for compliance with a legal or regulatory obligation that we are subject to.
            </p>
            <p>
            Generally, we do not rely on consent as a legal basis for processing your personal data other than in relation to sending third-party direct marketing communications to you via email or text message. You have the right to withdraw consent to marketing at any time by contacting the Data Protection Officer.
            </p>
      

          
            <p>
              <strong>Purposes for which we will use your personal data</strong>
            </p>
            <p>
              We have set out below, in a table format, a description of all the ways we plan to use your personal data, with the legal bases we rely on to do so.
            </p>

            <table className="" style={{ borderCollapse: "collapse", border: "1px solid black", width: "100%" }}>
        <tbody>
          <tr>
            <th style={{ border: "1px solid black", padding: "10px", textAlign: "center" }}>Purpose/Activity</th>
            <th style={{ border: "1px solid black", padding: "10px", textAlign: "center" }}>Type of data</th>
            <th style={{ border: "1px solid black", padding: "10px", textAlign: "center" }}>Lawful Basis for Processing</th>
          </tr>
          <tr>
            <td style={{ border: "1px solid black", padding: "10px", textAlign: "center" }}>To register you as a new customer</td>
            <td style={{ border: "1px solid black", padding: "10px", textAlign: "center" }}>(a) Identity<br />(b) Contact</td>
            <td style={{ border: "1px solid black", padding: "10px", textAlign: "center" }}>Performance of a contract</td>
          </tr>
        </tbody>
</table>

<br/>
<p>
              <strong><u>Marketing</u></strong>
          </p>
       <p>We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising.
       </p>
            
        
            <p>
              <strong><u>Promotional offers from us</u></strong>
          </p>
      
         
            <p>
            We may use your Identity, Contact, Technical, Usage, and Profile Data to form a view on what we think you may want or need, or what may be of interest to you.
            </p>
            <p>
            You will receive marketing communications from us if you have requested information from us or purchased goods or services from us and, in each case, you have not opted out of receiving that marketing.
            </p>
          
            <p>
              <strong><u>Third-party marketing</u></strong>
          </p>
         
            <p>
            We will get your express opt-in consent before we share your personal data with any company outside the IYKONS group of companies for marketing purposes.
             
            </p>
         
          <p>
              <strong><u>Opting out</u></strong>
          </p>
          
          
            <p>
            You can ask us or third parties to stop sending you marketing messages at any time by logging into the website and checking or unchecking relevant boxes to adjust your marketing preferences or by following the opt-out links on any marketing message sent to you or by mailing  <a href="mailto:data@iykons.com">data@iykons.com </a>at any time.at any time.
            </p>
        
<p>
Where you opt out of receiving these marketing messages, this will not apply to personal data provided to us as a result of a product/service purchase or other transactions.

</p>

<p>
              <strong><u>Cookies</u></strong>
          </p>
      
                <p>You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly. For more information about the cookies we use, please
              
                  <a href="https://web.archive.org/web/20220625023954/https:/www.iykons.com/cookie-policy">
                    {" "}
                   see here
                  </a>{" "}
               
              </p>
          


              <p>
              <strong><u>Change of purpose</u></strong>
          </p>
      <p>
      We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact the Data Protection Officer. If we need to use your personal data for an unrelated purpose, we will notify you and explain the legal basis which allows us to do so.
      </p>

              <br/>

              <h1 style={{fontSize: "24px", fontWeight: "bold"}}>5. Who We Share Your Personal Data With</h1>

            <p>
            We may have to share your personal data with the parties set out below for the purposes set out in the table in paragraph 4 above.
            </p>
<ul><li>Internal Third Parties</li></ul>
<ul>
    <ul>
      <li style={{ paddingLeft: '20px' }}>
      IYKONS Group of Companies.
      </li>
      </ul>
      </ul>

            <p>
  <ul>
    <li>External Third Parties Service</li>
  </ul>
  <ul>
    <ul>
      <li style={{ paddingLeft: '20px' }}>
      HM Revenue & Customs (HMRC), National Crime Agency (NCA), regulators, and other authorities (acting as processors or joint controllers) in the United Kingdom who require reporting of processing activities in certain circumstances.
<p>
Third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy notice.
</p>
      </li>
    </ul>
  </ul>
</p>


            <p>
            We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.
            </p>
          
            <br/>
            <h1 style={{fontSize: "24px", fontWeight: "bold"}}>6. International Transfers</h1>
          

          <p>We share your personal data within the IYKONS Group. This will involve transferring your data outside the United Kingdom and European Economic Area <strong>(EEA).</strong></p>
<p>Whenever we transfer your personal data out of the UK and EEA, we ensure a similar degree of protection is afforded to it by implementing safeguards according to the United Kingdom Data Protection Act 2018.</p>

<p>Please contact us if you want further information on the specific mechanism used by us when transferring your personal data out of the UK and EEA.</p>

<br/>
          <h1 style={{fontSize: "24px", fontWeight: "bold"}}>7. Data Security</h1>
<p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used, accessed, altered, or disclosed in an unauthorized way. We also limit access to your personal data to those employees, agents, contractors, and other third parties who have a business need to know.</p>
<p>They will only process your personal data on our instructions and they are subject to a duty of confidentiality. We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>
<br/>    
<h1 style={{fontSize: "24px", fontWeight: "bold"}}>8. Data Retention</h1>
<p>We will only retain your personal data for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>       
<p>To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.</p>

<br/>               
<h1 style={{fontSize: "24px", fontWeight: "bold"}}>9. Your Legal Rights</h1>
<p>Under data protection laws, you have rights including:</p>

<p><b>The right to access:</b> You have the right to request copies of your personal data.
<br/><b>The right to rectification:</b> The right to rectification: You have the right to request that we correct any information you believe is inaccurate or incomplete.
<br/><b>The right to erasure:</b> You have the right to request that we erase your personal data, under certain conditions.
<br/><b>The right to restrict processing:</b> You have the right to request that we restrict the processing of your personal data, under certain conditions.
<br/><b>The right to data portability:</b> You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
<br/><b>The right to withdraw consent:</b> You have the right to withdraw your consent at any time where IYKONS Limited relied on your consent to process your personal information.
</p>
<p>If you wish to exercise any of these rights, please contact us.</p>       
          
          
          <br/>
          <h1 style={{fontSize: "24px", fontWeight: "bold"}}> 10. Changes to Our Privacy Policy</h1>
          <p>
          We keep our privacy policy under regular review and we will place any updates on this web page. This privacy policy was last updated on October 18, 2024.
            </p>
            
        </div>
      </section>

      <section id="servicesj">
        <div className="container">
          <header className="section-header wow fadeInUp">
            <h4></h4>
            <br />
          </header>

          <div className="row"></div>
        </div>
        </section>
      </div>
      <Footer />
    </>
  );
}