
import "../Blog/Blog.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import SingleBlogPost from '../Blog/SingleBlogPost'
import image1 from '../assets/img/counts-bg.jpg';
import axios from "axios";
import { Link, Routes, Route } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Header from "../Header/Header";
import { toast } from "react-toastify";
function DeletePage() {
  const [selectedCategory, setSelectedCategory] = useState(null);
	const [searchQuery, setSearchQuery] = useState('');
  const [blogData, setBlogData] = useState([]);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://siteapi.iykons.com/api/Post');
        setBlogData(response.data);

        const categoryResponse = await axios.get('https://siteapi.iykons.com/api/Category/GetCategoryAll');
        setCategories(categoryResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [blogData]);


  const filteredBlogPosts = blogData.filter((post) => {
    const categoryMatch = selectedCategory === null || post.category === selectedCategory;
    const searchMatch = post.title.toLowerCase().includes(searchQuery.toLowerCase());

    return categoryMatch && searchMatch;
  });

  const handleDelete = async (postId) => {
    try {
      const response = await axios.delete(`https://siteapi.iykons.com/api/Post/${postId}`);
      if (response.status === 204) {
        
        const updatedResponse = await axios.get('https://siteapi.iykons.com/api/Post');
        setBlogData(updatedResponse.data);
        toast.success('Post deleted successfully');
      } else {
        toast.error('Failed to delete post');
      }
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };
  
  const toggleForm = () => {
   navigate('/AdminPanel')
  };
  const handleDeletebutton = () => {
    navigate('/DeletePage')
  };
  return (

    <><Header/>
    <div className="container3">
      <div className="button-group">

       
        <button className="button" onClick={toggleForm}>
          Add
        </button>

        <button className="button" >
          Modify
        </button>
        <button className="button" onClick={handleDeletebutton}>
          Delete
        </button>
        <button className="button" >
          Logout
        </button>


      </div>
    </div><main id="main">

        <section id="blog" className="blog">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div className="col-lg-8 entries">
                {blogData.slice().reverse().map((post) => (
                  <article className="entry" key={post.postId}>
                    <div className="entry-img">
                      {post.mediaItems.length > 0 && (
                        post.mediaItems.map((mediaItem) => {

                          return (
                            <img
                              key={mediaItem.imageId}
                              src={`https://siteapi.iykons.com/Upload/StaticContent/${mediaItem.filename}`}
                              alt={mediaItem.filename}
                              className="img-fluid" />
                          );
                        })
                      )}
                    </div>
                    <h2 className="entry-title" dangerouslySetInnerHTML={{ __html: post.title }} />


                    <div className="entry-content">
                      {/* Rendering HTML content, be careful with user-generated content */}
                      <div dangerouslySetInnerHTML={{ __html: post.description }} />
                    </div>
                    <div>tage</div>
                    <div className="read-more">
                      <button className="btn btn-danger" onClick={() => handleDelete(post.postId)}>Delete</button>
                    </div>
                  </article>
                ))}

              </div>
            </div>
          </div>
        </section>
      </main></>
  )
}

export default DeletePage