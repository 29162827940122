import React from "react";
import "./BSC.css";
import about_1 from "../image/image/bus3.jpeg";
import about_2 from "../image/image/bus2.jpeg";
import about_3 from "../image/image/bus1.jpeg";
import about_4 from "../image/image/bus4.jpeg";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import SendMessage from "../SendMessaage/SendMessage";
import video from "../Video/BusinessSupport.mp4";
import { Helmet } from "react-helmet";
export default function BusinesSupportConsultation() {
  const pageId=4;
  return (
    <>
    <Helmet>
    <script type="application/ld+json">
        {`{
"@context" : "http://schema.org",
"@type" : "WebSite", 
"name" : "IYKONS",
"url" : "https://www.iykons.com/",
"potentialAction" : {
    "@type" : "SearchAction",
    "target" : "https://www.iykons.com/search?q={search_term}",
    "query-input" : "required name=search_term"
  }
}
  `}
</script>  

    <script type="application/ld+json">
          {`
          {
              "@context": "http://schema.org",
              "@type": "Organization",
              "name": "Iykons Business Services",
              "url": "https://www.iykons.com/",
              "logo": "https://www.iykons.com/static/media/Logo.10cd63fcf794bdb64f91.png",
              "contactPoint": {
                "@type": "ContactPoint",
                "contactType": "customer support",
                "email": "info@iykons.com",
                "url": "/"
              },
              "sameAs": [
                "https://twitter.com/jey_raj",
                "https://www.facebook.com/iykons/",
                "https://www.tiktok.com/@iykons.uk?lang=en",
                "https://www.linkedin.com/company/iykons-ltd/",
                "https://www.instagram.com/iykon.uk/"
              ]
          }
          `}
        </script>
        {/* Google Tag Manager script */}
        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-W79GB927');
          `}
        </script>

        {/* Google Tag Manager noscript */}
        <noscript>
          {`
          <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W79GB927"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>
          `}
        </noscript>
        <title>Best Business Finance solutions Companies| Iykons | UK</title>
<meta name="description" content="Collaborate with Iykons, a trusted leader in business solutions. Trusted globally for finance, solutions, and support."/>
<link rel="canonical" href="https://iykons.com/BusinessSupport"/>
      </Helmet>
      <Header />
      <section className="imagerow"style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}>
        <div className="page-head4 header-text">
          <video autoPlay muted loop id="bg-video4">
            <source src={video} type="video/mp4" />
            {/* Add additional source elements for other video formats -*/}
            Your browser does not support the video tag.
          </video>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>Busines Support & Consultation</h1>
                <span>
                  <strong style={{ color: 'white' }}>
                  Welcome to our Business Support Solutions & Consultation hub, where we empower entrepreneurs and businesses to thrive. Whether you're launching a new venture or improving an existing one, our seasoned professionals are committed to tailoring solutions for your success. Partner with us on your path to growth and prosperity.
                  </strong>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="perant1">
        <div className="body1">
          <div class="container6">
            <div class="card">
              <div class="imgBx">
                <img
                  src={about_1}
                  alt="A scene from our latest product launch event"
                />
              </div>
              <div class="content">
                <h2>Creating a Business</h2>
                <p style={{textAlign:'center',fontSize:14.3}}>
                We excel in ensuring top-notch, up-to-date corporate documentation in line with current regulations. Our comprehensive services cover the entire company formation process, including offering cost-effective company addresses, assistance with bank account setup, startup counselling, and expert legal and regulatory guidance.


                </p>
              </div>
            </div>
            <div class="card">
              <div class="imgBx">
                <img
                  src={about_2}
                  alt="Preparation of a Business Plan launch event"
                />
              </div>
              <div class="content">
                <h2>Preparation of a Business Plan</h2>
                <p style={{textAlign:'center',fontSize:14.3}}>
                Beyond just a means to secure funding, a well-prepared business plan serves as a vital framework for informed decision-making, a platform for clear idea communication, and a benchmark for assessing corporate performance.
</p>

                  <ul>
                    <li>
                      A framework that allows you to make sound decisions.
                    </li>
                    <li>A foundation for easily communicating ideas.</li>
                    <li>
                      A standard against which corporate performance can be
                      measured.
                    </li>
                  </ul>
                
              </div>
            </div>
            <div class="card">
              <div class="imgBx">
                <img src={about_3} alt="Postal Service Applications" />
              </div>
              <div class="content">
                <h2>Postal Service Applications</h2>
                <p style={{textAlign:'center',fontSize:14.3}}>
                Seeking Post Office ownership or need assistance with the application and evaluation process?

We handle everything from online post office financial assessment applications to business plan development, including collaboration and coordination with all stakeholders. Our dispute resolution experts also excel in resolving various commercial conflicts, with strong connections to London city legal firms.

                </p>
              </div>
            </div>

            <div class="card">
              <div class="imgBx">
                <img src={about_4} alt="Outsourced Business Support" />
              </div>
              <div class="content">
                <h2>Outsourced Business Support</h2>
                <p style={{textAlign:'center',fontSize:14.3}}>
                IYKONS is your trusted partner for outsourced solutions, specialising in HR, accounting, bookkeeping, payroll, and tax return services tailored for SMEs. By entrusting your business needs to us, you gain valuable time to concentrate on company growth.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SendMessage pageId={pageId}/>
      <Footer />
    </>
  );
}
