// AdminPanel.js
import React, { useState, useEffect } from "react"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import "../Blog/Admin.css"
import Header from "../Header/Header"
import Select from "react-select"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import axios from "axios"
import { useNavigate } from 'react-router-dom'

const AdminPanel = () => {
  const [showForm, setShowForm] = useState(false)
  const [isEditMode] = useState()
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [selectedCategory, setSelectedCategory] = useState("")
  const [selectedTags, setSelectedTags] = useState([])
  const [categories, setCategories] = useState([])
  const [tags, setTags] = useState([])
  const [, setPostId] = useState(0)
  const [selectedImage, setSelectedImage] = useState(null)
  const [imagePreview, setImagePreview] = useState(null)
  const [isPopupOpen, setPopupOpen] = useState(false)
  const [popupInputValue, setPopupInputValue] = useState("")
  const navigate = useNavigate()

  useEffect(() => {

    const fetchCategories = async () => {
      try {
        const response = await axios.get("https://siteapi.iykons.com/api/Category/GetCategory")
        setCategories(response.data)
      } catch (error) {
        toast.error("Error fetching categories:")
      }
    }

    const fetchTags = async () => {
      try {
        const tagResponse = await axios.get(`http:s//siteapi.iykons.com/api/Tag/GetTag/${selectedCategory}`)
        setTags(tagResponse.data)

      } catch (error) { }

    }
    fetchTags()
    fetchCategories()
  }, [selectedCategory])



  const createPost = async (data, categoryId) => {
    try {
      const tagsString = data.tags.join(',')

      const postData = { ...data, tags: [tagsString] }
      const response = await axios.post(`https://siteapi.iykons.com/api/Post/create-post/${categoryId}`, postData)
      const pid = response.data.postId
      setPostId(pid)
      console.log("postiddddd", pid)

      return pid

    } catch (error) {
      toast.error("Error creating post")
    }
  }

  const uploadMedia = async (imageFile, postId) => {
    try {
      const file = new FormData()
      file.append('file', imageFile)

      const response = await axios.post(`https://siteapi.iykons.com/api/Media/upload/${postId}` ,file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const contentType = response.headers['content-type']
      console.log('Content-Type:', contentType)
      return response.data
    } catch (error) {
      console.error('Error uploading media:', error)
      toast.error('Error uploading media')
    }
  }


  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const newPostId = await createPost(
        {
          title,
          description,
          tags: selectedTags.map((tag) => tag.label),

        },
        selectedCategory
      )

      
      if (selectedImage) {
        await uploadMedia(selectedImage, newPostId);
      }
      toast.success('Post Created Successfully')

      setTitle('')
      setDescription('')
      setSelectedCategory('')
      setSelectedTags([])
      setSelectedImage(null)
      setImagePreview('')
    } catch (error) {
      toast.error('Error creating post')
    }
  }
  const toggleForm = () => {
    setShowForm((prevShowForm) => ({
      showForm: !prevShowForm,
      isEditMode: true,
    }))
  }

  const editItem = () => {
    setShowForm({
      showForm: true,
      isEditMode: true,
    })
  }

  const handleTitleChange = (value) => {
    setTitle(value)
  }

  const handleDescriptionChange = (value) => {
    setDescription(value)
  }

  const handleTagsChange = (selectedTags) => {
    setSelectedTags(selectedTags)
  }

  const handleChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedCategory(selectedOption.value)
    } else {
      setSelectedCategory("")
    }
  }

  const handleDelete = () => {
    navigate('/DeletePage')
  }
  const handleLogout = () => { }

  const handleImageChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      const previewURL = URL.createObjectURL(file)
      setSelectedImage(file)
      setImagePreview(previewURL)
    }
  }
  

  const closePopup = () => {
    setPopupOpen(false)
  }


  const savePopupData = () => {
    console.log("Saving data from popup:", popupInputValue)
    setPopupInputValue("")
    closePopup()
  }

  return (
    <>
      <Header />
      <div className="container3">

        <div className="button-group">
          {isEditMode ? (
            <button className="button" onClick={toggleForm}>
              repost
            </button>
          ) : (
            <button className="button" onClick={toggleForm}>
              Add
            </button>
          )}
          <button className="button" onClick={editItem}>
            Modify
          </button>
          <button className="button" onClick={handleDelete}>
            Delete
          </button>
          <button className="button" onClick={handleLogout}>
            Logout
          </button>
          {/* Add a button to open the popup */}

        </div>

        {showForm && (
          <form id="image-form">
            <h2>{isEditMode ? "Edit Image" : "Add Image"}</h2>
            <div className="form-field">
              <label htmlFor="category">Category:</label>
              <Select
                id="category"
                name="category"
                onChange={handleChange}
                options={categories.map((category) => ({
                  value: category.categoryId,
                  label: category.categoryName,
                }))}
              />
            </div>

            <div className="form-field">
              <label htmlFor="title">Title:</label>
              <ReactQuill value={title} onChange={handleTitleChange} />
            </div>
            <div className="form-field">
              <label htmlFor="description">Description:</label>
              <ReactQuill value={description} onChange={handleDescriptionChange} />
            </div>

            <div className="form-field">
              <label htmlFor="tags">Tags:</label>
              <Select
                id="tags"
                name="tags"
                isMulti
                value={selectedTags}
                options={tags.map((tag) => ({
                  value: tag.tagId,
                  label: tag.tagName,
                }))}
                onChange={handleTagsChange}
              />
            </div>


            <div className="form-field">
              <label htmlFor="image">Image:</label>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input type="file" accept="image/*" onChange={handleImageChange} />
                {imagePreview && (
                  <img src={imagePreview} alt="Selected Preview" style={{ maxWidth: "100px", maxHeight: "100px", marginLeft: "10px" }} />
                )}
              </div>
            </div>
            <button className="button" type="submit" onClick={handleSubmit}>
              {isEditMode ? "Update" : "Post"}
            </button>
          </form>
        )}


        {isPopupOpen && (
          <div className="popup">
            <div className="popup-content">
              <span className="close" onClick={closePopup}>
                &times
              </span>
              <h2>Popup Window</h2>
              <label htmlFor="popupInput">Input:</label>
              <input type="text" id="popupInput" value={popupInputValue} onChange={(e) => setPopupInputValue(e.target.value)} />
              <button onClick={savePopupData}>Save</button>
              <button onClick={closePopup}>Close</button>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default AdminPanel
