
import React ,{useEffect,useState}from 'react';

import "../assets/css/style.css";

import { useParams } from 'react-router-dom';
import axios from "axios";
import { toast } from "react-toastify";
function SingleBlogPost() {
    const { id } = useParams();
    const [post, setPost] = useState(null);
    const [commentText, setCommentText] = useState('');
    useEffect(() => {
      const fetchPost = async () => {
        try {
          const response = await axios.get(`https://siteapi.iykons.com/api/Post/${id}`);
          setPost(response.data);
        } catch (error) {
          console.error('Error fetching post data:', error);
        }
      };
  
      fetchPost();
    }, [id]);

    const handleCommentSubmit = async (event) => {
        event.preventDefault();
    
        // Get the current date and time
        const currentDate = new Date().toISOString();
    
        // Prepare the data for the API call
        const commentData = {
          commenddate: currentDate,
          commandText: commentText,
        };
    
        try {
          // Make the API call to submit the comment
          const response = await axios.post(`https://siteapi.iykons.com/api/Comment/${id}`, commentData);
          
          // Assuming the API call is successful, you can handle the response as needed
          toast.success('Comment submitted successfully:');
    
          // Clear the comment text after submission
          setCommentText('');
        } catch (error) {
            toast.error('Error submitting comment:');
        }
      };
    
    
  if (!post) {
    // If the post is still null, render loading or handle the null case
    return <div>Loading...</div>;
  }
  console.log("postdata",post)
    return (
        <>

            <main id="main">

                <section id="blog" className="blog">
                    <div className="container" data-aos="fade-up">
                        <div>
                            <div>
                                <article className="entry entry-single">
                                    <div className="entry-img">
                                    {post.postInfos.mediaItems.length > 0 && (
                                        post.postInfos.mediaItems.map((mediaItem) => {
                                            console.log("file", mediaItem.filename);
                                            return (
                                                <img
                                                    key={mediaItem.imageId}
                                                    src={`https://siteapi.iykons.com/Upload/StaticContent/${mediaItem.filename}`}
                                                    alt={mediaItem.filename}
                                                   
                                                    className="img-fluid"
                                                />
                                            );
                                        })
                                    )}
                                    </div>
                                  
                                        <a href="">
                                           <h2 className="entry-title" dangerouslySetInnerHTML={{ __html: post.postInfos.title }}/>
                                        </a>
                                    

                                    <div className="entry-content">
                                        <p dangerouslySetInnerHTML={{ __html: post.postInfos.description }} />
                                       
                                    </div>
                                    <div class="entry-meta">
                                        <ul>
                                            <li class="d-flex align-items-center"><i class="bi bi-person"></i> <a href="">Admin</a></li>
                                            <li class="d-flex align-items-center"><i class="bi bi-clock"></i> <a href=""><time datetime="2020-01-01">{new Date(post.postInfos.createDate).toLocaleDateString()}</time></a></li>
                                            <li class="d-flex align-items-center"><i class="bi bi-chat-dots"></i> <a href="">12 Comments</a></li>
                                        </ul>
                                    </div>
                                </article>
                                

                            </div>
                            

                            <div className="reply-form">
                                <h4>Leave a Reply</h4>
                                
                            <form onSubmit={handleCommentSubmit}>
                            <div className="row">
                                <div className="col form-group">
                                <textarea
                                    name="comment"
                                    className="form-control"
                                    placeholder="Your Comment*"
                                    value={commentText}
                                    onChange={(e) => setCommentText(e.target.value)}
                                />
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary">
                                Post Comment
                            </button>
                            </form>
                            </div>
                        </div>
                       
                    </div>


                </section>

            </main>


        </>


    );
}

export default SingleBlogPost
