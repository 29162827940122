import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/TechnologyandInnovation.css";
import about1 from "../image/image/Tech1.png";
import about2 from "../image/image/Tech2.png";
import about_8 from "../image/image/Webdevelopment.jpg";
import '../css/TechnologyandInnovation.css'
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import SendMessage from "../SendMessaage/SendMessage";
import { Link } from "react-router-dom";
import video from "../Video/Tech.mp4";
import { Helmet } from "react-helmet";
export default function TechnologyandInnovation() {
  return (
    <>
     <Helmet>
     <script type="application/ld+json">
        {`{
"@context" : "http://schema.org",
"@type" : "WebSite", 
"name" : "IYKONS",
"url" : "https://www.iykons.com/",
"potentialAction" : {
    "@type" : "SearchAction",
    "target" : "https://www.iykons.com/search?q={search_term}",
    "query-input" : "required name=search_term"
  }
}
  `}
</script> 

     <script type="application/ld+json">
          {`
          {
              "@context": "http://schema.org",
              "@type": "Organization",
              "name": "Iykons Business Services",
              "url": "https://www.iykons.com/",
              "logo": "https://www.iykons.com/static/media/Logo.10cd63fcf794bdb64f91.png",
              "contactPoint": {
                "@type": "ContactPoint",
                "contactType": "customer support",
                "email": "info@iykons.com",
                "url": "/"
              },
              "sameAs": [
                "https://twitter.com/jey_raj",
                "https://www.facebook.com/iykons/",
                "https://www.tiktok.com/@iykons.uk?lang=en",
                "https://www.linkedin.com/company/iykons-ltd/",
                "https://www.instagram.com/iykon.uk/"
              ]
          }
          `}
        </script>
        {/* Google Tag Manager script */}
        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-W79GB927');
          `}
        </script>

        {/* Google Tag Manager noscript */}
        <noscript>
          {`
          <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W79GB927"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>
          `}
        </noscript>
        <title>IT Service Providers for Fintech firms in Chennai | Iykons India</title>
<meta name="description" content="Iykons: Your premier choice for fintech IT solutions in India. Trusted by Chennai firms for development, technology, and enterprise services."/>
<link rel="canonical" href="https://iykons.com/TechnologyandInnovation"/>
      </Helmet>
      <Header />

      <section className="imagerow">
        <div className="page-head2 header-text">
          <video autoPlay muted loop id="bg-video2">
            <source src={video} type="video/mp4" />
            {/* Add additional source elements for other video formats**/}
            Your browser does not support the video tag.
          </video>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>Technology & Innovation</h1>
                <span>
                Iykons is your reliable ally for technology and innovation solutions. We help businesses embrace new tech, spark innovation, and achieve growth. From tech advice to digital transformation, we unlock new possibilities, shaping a future of endless innovation and technology solutions.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="body">
        <div className="row">
          <div className="col-md-6">
            <div className="card1">
              <img src={about1} className="card1-img" alt="" />
              <h4 className="cardC-img-title">
                BESPOKE APPLICATION DEVELOPMENT
              </h4>
              <div className="card1-body">
                <h3 className="card1-title">BESPOKE APPLICATION DEVELOPMENT</h3>
                <p className="card1-sub-title"></p>
                <p className="card1-info">
                  We have a complete framework of IT services that can deliver
                  Apps and solutions designed to drive efficiency and add value
                  to your business. We manage projects through mobile, web,
                  embedded, and cloud technologies, regardless of their size;
                  along with mobile solutions that enhance the user experience.
                  Whether you have any existing mobile solutions or are looking
                  to develop a completely new one, we can help you with our
                  expert review. Our developers focus on all dimensions of the
                  solution with user interface, cross-platform coverage,
                  scalability & interoperability, security & compliance, and
                  accelerated delivery.
                  <br />
                </p>
                <p className="card1-info">
                  With our expertise in multiple domains, our team of analysts
                  will guide you in analyzing your application concept and assess
                  it from the aspects of:
                </p>
                <ul className="card1-ultext">
                  <li>Feasibility</li>
                  <li>Technological complication</li>
                  <li>Platform and device compatibility</li>
                  <li>Market potential and competitiveness</li>
                  <li>Budget</li>
                  <li>Code reuse</li>
                  <li>Backend functionality reuse</li>
                  <li>UI/UX compatibility</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card1">
              <img src={about2} className="card1-img" alt="" />
              <h4 className="cardC-img-title">FINTECH SERVICES & SOLUTIONS</h4>
              <div className="card1-body">
                <h3 className="card1-title">FINTECH SERVICES & SOLUTIONS</h3>
                <p className="card1-sub-title"></p>
                <p className="card1-info">
                As one of the leading software development companies, IYKONS has earned the trust of fintech startups as well as other enterprises for their back-end Fintech needs by providing sturdy and secure digital fintech products powered by cutting-edge technology. We provide fintech app development services and dedicated teams to a wide range of enterprises, including fintech start-ups, traditional financial, banking operations and non-financial firms. Our deep expertise and breadth of experience assure on-time delivery of next-generation fintech products.
                  <br />
                </p>
                <p className="card1-info">Streamline your financial operations with our digital banking services and cost-effective portfolio systems. Embrace the future of finance with our secure digital currency solutions. Trust us as your reliable partner in delivering comprehensive and transformative FinTech services tailored to your unique needs.</p>
               
                <Link to="/Fintech">
                  <button className="card1-btn1">
                    Learn More about IYKONS Fintech
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

       
      </section>

      <SendMessage />
      <Footer />
    </>
  );
}
