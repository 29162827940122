import React from "react";
import "../css/Aboutus.css";
import myImage1 from "../image/image/image.jpg";
import { Helmet } from "react-helmet";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import SendMessage from "../SendMessaage/SendMessage";
import video from "../Video/Account.mp4";
export default function Aboutus() {
  const pageId=1;
  return (
    <>
    <Helmet>
    <script type="application/ld+json">
{`
  {
  "@context": "http://schema.org",
  "@type": "WebSite",
  "name": "IYKONS",
  "url": "https://www.iykons.com/",
  "potentialAction": {
    "@type": "SearchAction",
    "target": "https://www.iykons.com/search?q={search_term}",
    "query-input": "required name=search_term"
  }
}
`}
</script>
    
    <script type="application/ld+json">
          {`
          {
              "@context": "http://schema.org",
              "@type": "Organization",
              "name": "Iykons Business Services",
              "url": "https://www.iykons.com/",
              "logo": "https://www.iykons.com/static/media/Logo.10cd63fcf794bdb64f91.png",
              "contactPoint": {
                "@type": "ContactPoint",
                "contactType": "customer support",
                "email": "info@iykons.com",
                "url": "/"
              },
              "sameAs": [
                "https://twitter.com/jey_raj",
                "https://www.facebook.com/iykons/",
                "https://www.tiktok.com/@iykons.uk?lang=en",
                "https://www.linkedin.com/company/iykons-ltd/",
                "https://www.instagram.com/iykon.uk/"
              ]
          }
          `}
        </script>
        {/* Google Tag Manager script */}
        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-W79GB927');
          `}
        </script>

        {/* Google Tag Manager noscript */}
        <noscript>
          {`
          <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W79GB927"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>
          `}
        </noscript>
        
  {/* ... existing scripts and metadata ... */}
  
  {/* Add title */}
  <title>About Iykons: Your Partner for Digital Marketing, Ecommerce Solutions, App Dev, & Payroll Services in the UK</title>
  
  {/* Add meta description */}
  <meta name="description" content="At Iykons, our expertise lies in digital marketing, ecommerce solutions, app development, and payroll services across the UK. Collaborate for tailored solutions meeting your business requirements." />
  
  {/* Add canonical link */}
  <link rel="canonical" href="https://iykons.com/Aboutus" />

      </Helmet>
      <Header />
      <section className="imagerow" style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}>
        <div className="page-head1 header-text">
          <video autoPlay muted loop id="bg-video">
            <source src={video} type="video/mp4" />
            {/* Add additional source elements for other video formats */}
            Your browser does not support the video tag.
          </video>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>About Us</h1>
               </div>
            </div>
          </div>
        </div>
      </section>
      {/* 
      <section className="imagerow">
        <div className="page-head4 header-text">
          <video autoPlay muted loop id="bg-video4">
            <source src={video} type="video/mp4" />
            {/* Add additional source elements for other video formats }
            Your browser does not support the video tag.
          </video>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>About us</h1>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </section>
    */}
    
      <main id="main">
      <section class="ma">
        <section id="about" class="about mt-5">
            <div className="row">
               <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="our-story" >
                 <h3>OUR STORY</h3>
                  <p>
                    Our excellent team of specialists can help you and your
                    business with your financial, IT, and business support
                    needs. Our mission is to meet the needs of SMEs from under
                    one roof. We relish the challenges of finding the best
                    solutions for you and thrive on making our clients happy. To
                    find out more about our services, please do not hesitate to
                    contact us online or at your nearest local office. We are
                    based in
                  </p>
                  <ul>
                    <li>
                      <i class="bi bi-check-circle"></i> Chessington, Greater
                      London, United Kingdom
                    </li>
                    <li>
                      <i class="bi bi-check-circle"></i> Chennai, Tamil Nadu,
                      India
                    </li>
                    <li>
                      <i class="bi bi-check-circle"></i> Sydney, Australia
                    </li>
                    <li>
                      <i class="bi bi-check-circle"></i> Vavuniya, SriLanka
                    </li>

                  </ul>
                  <p>
                    IYKONS is an ACCA-accredited chartered accountancy firm. We
                    undertake all aspects of Accountancy and Tax work. Contact
                    IYKONS to find out about other bespoke solutions we offer.
                  </p> 
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 about-img"></div>
            </div>
        </section>
</section>
        {/*set two */}
<section class="ser1">
<div class="row hi mt-5">
<h3 class="company">Company History</h3>
  <div class="d-md-flex post-entry-2 half">
    <div class="col-lg-6 col-md-6 col-sm-12 me-2 d-flex justify-content-center align-items-center">
    <img src={myImage1} alt="" class="img-fluid"/>
    </div>
    
    
    <div class="col-lg-6 col-md-6 col-sm-12 ps-md-4  mt-md-0">
     
     <p class="p">
        Jey Founded IYKONS in 2008 after observing a significant gap in
        the SME market for a one-stop solution for striving business
        people. IYKONS organization and the team were designed to
        address any challenge a business person throws. We will solve it
        or find a way to solve it within the customer’s timeframe.
        Hundreds of IYKONS customers are out there to prove our
        problem-solving history.
      </p>
      <p class="p">
        IYKONS manages 500+ portfolios in various sectors, from
        not-for-profit to aeronautical industries.
      </p>
      <p class="p">What Makes Us the Best Choice?</p>
      <ul class="list">
        <li>Cost-effective fixed fee services.</li>
        <li>Wide range of industry expertise.</li>
        <li>Fully accredited and regulated.</li>
        <li>
          Follow strict regulatory standards to safeguard our clients
          from possible legal actions.
        </li>
        <li>Focused on customer needs and on-time delivery.</li>
        <li>
          Personalized services with laser-focused attention to every
          client.
        </li>
        <li>IYKONS – Outsourcing to excellence.</li>
      </ul>  
    </div>
  </div>
</div>
</section>
          <div class="backpage mt-5 mb-5">
            
              <h1>IYKONS – Give Back</h1>
              <h2>
                “We make a living by what we get, but we make a life by what we
                give.” Winston Churchill.
              </h2>
              <p class="par text-justify">
                At IYKONS, we believe in giving back to the community; this
                makes our work truly meaningful. IYKONS colleagues volunteer
                their valuable time to empower local charities and encourage
                communities to set up charitable entities every year. In
                addition, IYKONS staff members dedicate a significant amount of
                weekly time to help empower community organisations by helping
                them with legal, compliance and financial aspects. At the same
                time, IYKONS group of companies contribute thousands of pounds a
                year to established charities here and around the developing
                world through our charitable arm, Atchaya Foundation.
              </p>
            </div>
      <div className="send mt-5">
      <SendMessage pageId={pageId} />
      <Footer />

      </div>
      </main>
    </>
  );
}
