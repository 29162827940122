import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Home.css";
import { Helmet } from "react-helmet";
import "./Icons/font-awesome/css/font-awesome.min.css";
import image1 from "./img/intro-carousel/1.jpg";
import aboutmisson from "./img/about-mission.jpg";
import aboutplan from "./img/about-plan.jpg";
import "./Icons/css/ionicons.min.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ImageSlider from "../ImageSlider/ImageSlider";
import { Link } from "react-router-dom";
export default function Home() {
  return (
    <>
    <Helmet>
      
    <script type="application/ld+json">
          {`
          {
              "@context": "http://schema.org",
              "@type": "Organization",
              "name": "Iykons Business Services",
              "url": "https://www.iykons.com/",
              "logo": "https://www.iykons.com/static/media/Logo.10cd63fcf794bdb64f91.png",
              "contactPoint": {
                "@type": "ContactPoint",
                "contactType": "customer support",
                "email": "info@iykons.com",
                "url": "/"
              },
              "sameAs": [
                "https://twitter.com/jey_raj",
                "https://www.facebook.com/iykons/",
                "https://www.tiktok.com/@iykons.uk?lang=en",
                "https://www.linkedin.com/company/iykons-ltd/",
                "https://www.instagram.com/iykon.uk/"
              ]
          }
          `}
        </script>
        <script type="application/ld+json">
        {`{
"@context" : "http://schema.org",
"@type" : "WebSite", 
"name" : "IYKONS",
"url" : "https://www.iykons.com/",
"potentialAction" : {
    "@type" : "SearchAction",
    "target" : "https://www.iykons.com/search?q={search_term}",
    "query-input" : "required name=search_term"
  }
}
  `}
</script>  

        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-W79GB927');
          `}
        </script>

        {/* Google Tag Manager noscript */}
        <noscript>
          {`
          <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W79GB927"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>
          `}
        </noscript>
        <title>Expert Accountants & Business Solutions in South London | Iykons</title>
<meta name="description" content="Iykons offer expert accounting and business solutions tailored for South London, Surbiton, and Surrey. From accounting and bookkeeping services to digital marketing, trust us as your comprehensive business partner."/>
<link rel="canonical" href="https://iykons.com"/>
      </Helmet>
      <Header/>
      <ImageSlider/> 
      <section className="welcome" >
        <div className="container2">
          <header>
            <div className="content-wrapper">
              <div className="text-content">
                <h1>Welcome to IYKONS</h1>
                <h6>
                  <p>
                    <p>
                      IYKONS offers complete financial, IT, and business support
                      solutions for companies and individuals across three
                      continents.
                    </p>
                    <p>
                      Our excellent team of specialists can help you and your
                      business with your financial, IT, and business support
                      needs. Our mission is to meet the needs of SMEs under one
                      roof. We relish the challenges of finding the best
                      solutions for you, and we thrive on making our clients
                      happy.
                    </p>
                    <p>
                      To find out more about our services, please do not
                      hesitate to contact us online or at your nearest local
                      office.
                    </p>
                    <p>We are based in</p>
                    <ul>
                      <li>Chessington, Greater London, United Kingdom</li>
                      <li>Chennai, Tamil Nadu, India</li>
                      <li>Sydney, Australia</li>
                      <li>Vavuniya, SriLanka</li>
                    </ul>
                    <p>
                      IYKONS is an ACCA-accredited chartered accountancy firm.
                      We undertake all aspects of Accountancy and Tax work.
                    </p>
                    <p>
                      Contact IYKONS to find out about other bespoke solutions
                      we offer.
                    </p>
                  </p>
                </h6>
              </div>
              <div className="image-content">
                <img src={image1} alt="" className="img-fluids" />
              </div>
            </div>
            <div className="facts-img"></div>
          </header>
        </div>
      </section>
      {/* #intro */}
      {/*==========================
About Us Section
    ============================*/}
<section class="ser">
      <section id="services">
        <div className="container">
          <header className="section-header wow fadeInUp">
            <h1> Our Services</h1>
            <br />

            <p></p>
          </header>
          <div className="row">
            <div
              className="col-lg-4 col-md-6 box wow bounceInUp"
              data-wow-duration="1.4s"
            >
              <div className="icon">
                <i className="ion-ios-analytics-outline" />
              </div>
              <h4 className="title">
                <Link to="/AccountandFinance">Accounting & Finance</Link>
              </h4>
              <p className="description"></p>
            </div>
            <div
              className="col-lg-4 col-md-6 box wow bounceInUp"
              data-wow-duration="1.4s"
            >
              <div className="icon">
                <i className="ion-ios-bookmarks-outline" />
              </div>
              <h4 className="title">
                <Link to="/TechnologyandInnovation">
                  Technology & Innovation
                </Link>
              </h4>
              <p className="description"></p>
            </div>
            <div
              className="col-lg-4 col-md-6 box wow bounceInUp"
              data-wow-duration="1.4s"
            >
              <div className="icon">
                <i className="ion-ios-paper-outline" />
              </div>
              <h4 className="title">
                <Link to="/DigitalMarketing">Digital Marketing & Branding</Link>
              </h4>
              <p className="description"></p>
            </div>
            <div
              className="col-lg-4 col-md-6 box wow bounceInUp"
              data-wow-delay="0.1s"
              data-wow-duration="1.4s"
            >
              <div className="icon">
                <i className="ion-ios-speedometer-outline" />
              </div>
              <h4 className="title">
                <Link to="/BusinessPersonalFinance">
                  Business & Personal Finance
                </Link>
              </h4>
              <p className="description"></p>
            </div>
            <div
              className="col-lg-4 col-md-6 box wow bounceInUp"
              data-wow-delay="0.1s"
              data-wow-duration="1.4s"
            >
              <div className="icon">
                <i className="ion-ios-people-outline" />
              </div>
              <h4 className="title">
                <Link to="/BusinessSupport">
                  Business Support & Consultancy
                </Link>
              </h4>
              <p className="description"></p>
            </div>
          </div>
        </div>
      </section>
</section>
      {/*    ============================*/}

      <section id="about">
        <div className="container">
          <header className="section-header">
            <h1>Our Products</h1>
            <p>
              <br />
              <br />
            </p>
          </header>
          <div className="row ">
            <div className="col-md-6 wow fadeInUp d-flex align-items-stretch">
              <div className="about-col1">
                <div className="img">
                  <img src={aboutplan} alt="" className="img-fluid1" />
                </div>
                <h2 className="title">
                  <Link to="/Iykonnect">IYCONNECT</Link>
                </h2>
                <p style={{textAlign:'center'}}>
                IYCONNECT is your go-to practice management software solution for enhancing productivity and streamlining workflows. This powerful platform is designed to connect businesses and individuals, enabling seamless collaboration and Communication.</p>
                <p style={{textAlign:'center'}}>Introducing IYCONNECT, revolutionising collaboration and communication by IYKONS with comprehensive features and user-friendly interface.

                </p>
              </div>
            </div>
            <div className="col-md-6 wow fadeInUp d-flex align-items-stretch" >
              <div className="about-col1">
                <div className="img">
                  <img src={aboutmisson} alt="" className="img-fluid1" />
                </div>
                <h2 className="title">
                  <Link to="/Fantach">IYKONS Fintech </Link>
                </h2>
                <p style={{textAlign:'center'}}>
                At IYKONS, we are at the forefront of this digital transformation, offering a comprehensive suite of fintech solutions to meet the diverse needs of businesses and individuals.</p>
                <p style={{textAlign:'center'}}>At IYKONS, we are dedicated to driving the fintech revolution and transforming how financial services are delivered.

                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
