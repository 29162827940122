import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../Blog/Login.css';
//import AdminPanel from './AdminPanel';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
 
  const navigate = useNavigate()

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://siteapi.iykons.com/api/User/login', {
        email: email,
        password: password,
      });

      if (response.status === 200) {
       
        const token = response.data;
        // const token = response.data.token
        // Store the token in local storage
        localStorage.setItem('token', token);
       
        // Save the JWT token to the local storage
        
        navigate('/AdminPanel');
        toast.success("Success Login !");
        
      }
    } catch (error) {
      
      if (error.response) {
        
        toast.error('User Name or Password Incorrect');
      } else if (error.request) {
        // The request was made but no response was received
        toast.error('Network error. Please try again.');
      } else {
        // Something happened in setting up the request that triggered an Error
        toast.error('An unexpected error occurred. Please try again.');
      }
    }
   
  };



  return (
    <div className="login">
      <span className="loginTitle">Login</span>
      <form className="loginForm" onSubmit={handleLogin}>
        <label>Email</label>
        <input
          className="loginInput"
          type="text"
          placeholder="Enter your email..."
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label>Password</label>
        <input
          className="loginInput"
          type="password"
          placeholder="Enter your password..."
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className="loginButton" type="submit">
          Login
        </button>
      </form>

      
    </div>
  );
}
