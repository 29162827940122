import React, { useState, useEffect } from 'react';
import Header from "../Header/Header"
import { Link } from "react-router-dom";
import "../Blog/Blog.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import axios from "axios";

function HomePage() {
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [searchQuery, setSearchQuery] = useState('');
	const [blogData, setBlogData] = useState([]);
	const [categories, setCategories] = useState([]);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get('https://siteapi.iykons.com/api/Post');
				setBlogData(response.data);

				const categoryResponse = await axios.get('https://siteapi.iykons.com/api/Category/GetCategoryAll');
				setCategories(categoryResponse.data);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, [blogData]);


	const filteredBlogPosts = blogData.filter((post) => {
		const categoryMatch = selectedCategory === null || post.category === selectedCategory;
		const searchMatch = post.title.toLowerCase().includes(searchQuery.toLowerCase());

		return categoryMatch && searchMatch;
	});

	const handleSearchInputChange = (event) => {
		setSearchQuery(event.target.value);
	};

	return (
		<>
			<Header />
			<main id="main">

				<section id="blog" className="blog">
					<div className="container" data-aos="fade-up">
						<div className="row">
							<div className="col-lg-8 entries">
								{blogData.slice().reverse().map((post) => (
									<article className="entry" key={post.postId}>
										<div className="entry-img">
											{post.mediaItems.length > 0 && (
												post.mediaItems.map((mediaItem) => {

													return (
														<img
															key={mediaItem.imageId}
															src={`https://siteapi.iykons.com/Upload/StaticContent/${mediaItem.filename}`}
															alt={mediaItem.filename}
															className="img-fluid"
														/>
													);
												})
											)}
										</div>
										<h2 className="entry-title" dangerouslySetInnerHTML={{ __html: post.title }} />


										<div className="entry-content">
											{/* Rendering HTML content, be careful with user-generated content */}
											<div dangerouslySetInnerHTML={{ __html: post.description }} />
										</div>
										<div>tage</div>
										<div className="read-more">
											<Link to={`/singlePost/${post.postId}`}>Read More</Link>
										</div>
									</article>
								))}

							</div>
							{/* End blog entries list */}
							<div className="col-lg-4">
								<div className="sidebar">
									<h3 className="sidebar-title">Search</h3>
									<div className="sidebar-item search-form">
										<form action="">
											<input
												type="text"
												placeholder="Search..."
												value={searchQuery}
												onChange={handleSearchInputChange}
											/>
										</form>
									</div>
									{/* End sidebar search formn*/}
									<h3 className="sidebar-title">Categories</h3>
									<div className="sidebar-item categories">
										<ul>
											<li key="all">
												<a href="#" onClick={() => setSelectedCategory(null)}>
													All <span>({blogData.length})</span>
												</a>
											</li>
											{categories.map((category) => (
												<li key={category.categoryId}>
													<a href="#" onClick={() => setSelectedCategory(category.categoryId)}>
														{category.categoryName}{' '}
														<span>(
															{
																blogData.filter((post) => post.categoryId === category.categoryId).length
															}
															)</span>
													</a>
												</li>
											))}
										</ul>

									</div>

									<h3 className="sidebar-title">Recent Posts</h3>

									<div className="sidebar-item recent-posts">
										{blogData.slice().reverse().map((post) => (

											<div className="post-item clearfix" key={post.postId}>
												 {post.mediaItems && post.mediaItems.length > 0 && post.mediaItems[0].filename && (
													<img
													src={`https://siteapi.iykons.com/Upload/StaticContent/${post.mediaItems[0].filename}`}
													alt=""
													/>
												)}

												<a href={`blog/${post.postId}`}>
													<h6 className="entry-title" dangerouslySetInnerHTML={{ __html: post.title }} />

												</a>

												<time dateTime={post.createDate}>{new Date(post.createDate).toLocaleDateString()}</time>
											</div>

										))}
									</div>
								</div>
							</div>


						</div>

					</div>
				</section>

			</main>

			<a
				href="#"
				className="back-to-top d-flex align-items-center justify-content-center"
			>
				<i className="bi bi-arrow-up-short" />
			</a>
		</>
	);
}

export default HomePage;
