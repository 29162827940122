import React from "react";

import "../css/BusinessFinace.css"; // You can define custom CSS styles here
import "../Home/Icons/font-awesome/css/font-awesome.min.css";
import "../Home/Icons/css/ionicons.min.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import SendMessage from "../SendMessaage/SendMessage";
import video from "../Video/BusinessFinance.mp4";
import { Helmet } from "react-helmet";
export default function BusinessPersonalFinance() {
  const pageId=6;
  return (
    <>
     <Helmet>
     <script type="application/ld+json">
        {`{
"@context" : "http://schema.org",
"@type" : "WebSite", 
"name" : "IYKONS",
"url" : "https://www.iykons.com/",
"potentialAction" : {
    "@type" : "SearchAction",
    "target" : "https://www.iykons.com/search?q={search_term}",
    "query-input" : "required name=search_term"
  }
}
  `}
</script>  

     <script type="application/ld+json">
          {`
          {
              "@context": "http://schema.org",
              "@type": "Organization",
              "name": "Iykons Business Services",
              "url": "https://www.iykons.com/",
              "logo": "https://www.iykons.com/static/media/Logo.10cd63fcf794bdb64f91.png",
              "contactPoint": {
                "@type": "ContactPoint",
                "contactType": "customer support",
                "email": "info@iykons.com",
                "url": "/"
              },
              "sameAs": [
                "https://twitter.com/jey_raj",
                "https://www.facebook.com/iykons/",
                "https://www.tiktok.com/@iykons.uk?lang=en",
                "https://www.linkedin.com/company/iykons-ltd/",
                "https://www.instagram.com/iykon.uk/"
              ]
          }
          
          `}
        </script>
        {/* Google Tag Manager script */}
        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-W79GB927');
          `}
        </script>

        {/* Google Tag Manager noscript */}
        <noscript>
          {`
          <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W79GB927"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>
          `}
        </noscript>
        <title>UK Accountancy Practice Management Software Vat Service Provider Company</title>
<meta name="description" content="Explore top-notch UK accountancy practice management software and VAT services with Iykons. Your trusted provider for efficient financial solutions."/>
<link rel="canonical" href="https://iykons.com/BusinessPersonalFinance"/>

      </Helmet>
      <Header />
      <section className="imagerow" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
        <div className="page-head1 header-text">
          <video autoPlay muted loop id="bg-video" className="video-with-shadow">
            <source src={video} type="video/mp4" />
            {/* Add additional source elements for other video formats */}
          </video>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1 className="text-white">Business & Personal Finance</h1>
                <span>Introducing IYCONNECT, Revolutionizing Collaboration and Communication by IYKONS. This powerful platform is designed to connect businesses and individuals, enabling seamless collaboration and communication. With its comprehensive features and user-friendly interface, IYCONNECT is your go-to practice management software solution for enhancing productivity and streamlining workflows.</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container mt-4">
        <div className="row">
          <div className="col-md-12">
           
            <p className="text-justify"> {/* Add text-justify class */}
              Onswin is an independent business broker that offers a
              comprehensive range of financial solutions to both individuals
              and businesses. We take satisfaction in identifying the best
              funding choices for every requirement, thanks to our specialised
              knowledge and skills across the finance sector.
            </p>

            
            <ul>
              <li className="text-justify">
                We can assist you in locating the cheapest mortgage rates for
                any type of buyer, whether it is a Buy to Let, a first-time
                buyer, or a re-mortgage. Onswin works with well-known
                FCA-approved mortgage lenders and intermediaries. For
                clarification, we do not offer mortgage advice or mortgage
                brokerage; however, we can handle all of the administrative
                details prior to and during the mortgage application.
              </li>
              <li className="text-justify">
                Our ability to lend commercially is boosted by our membership
                of the National Association of Commercial Finance Brokers
                (NACFB). Bridging loans, start-up loans, VAT loans, commercial
                loans, commercial mortgages, asset finance, and other services
                are available.
              </li>
              <li className="text-justify">
                Being an NACFB Member broker ensures that consumers and lenders
                are working with a professional expert who knows the market and
                works to the highest standards.
              </li>
              <li className="text-justify">
                In addition to this, we can also help you with income tax
                e-payment and income tax payment Services.
              </li>
              <li className="text-justify">
                Onswin is an extension of the Iykons team that is dedicated to
                providing administrative support for personal and business
                mortgages.
              </li>
            </ul>

            <p className="text-justify">
              For more information about Onswin’s services and products, please
              visit <a href="www.onswin.com">www.onswin.com</a>.<br/>
            

            
              Onswin is authorised and regulated by the Financial Conduct
              Authority (FCA) and accredited by the National Association of
              Commercial Finance Brokers (NACFB). We are registered with the
              Information Commissioner's Office (ZB342147).
            </p>
          </div>
        </div>
      </section>

      <SendMessage pageId={pageId}/>
      <Footer />
    </>
  );
}
