import React, { useRef } from 'react'
import emailjs from '@emailjs/browser'
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/SendMessage.css";
import headerLog from "../image/logos.png";
import axios from "axios";
import { toast } from "react-toastify";
export default function SendMessage({ pageId }) {
  const form = useRef()
  const sendEmail = (e) => {
    e.preventDefault()

    emailjs.sendForm('service_54ucz8j', 'template_mrc29tz', form.current, '1XLXownNy7vZxNN8g')
      .then((result) => {
        console.log(result.text)
        saveDataToServer()
        toast.success('FeedBack sent successfully');
        form.current.reset()
      }, (error) => {
        alert("Message Not Send")
        console.log(error.text)
      });
  };
  const saveDataToServer = async () => {
    const formData = new FormData(form.current);
    const jsonData = {
      name: formData.get('name'),
      email: formData.get('email'),
      subject: formData.get('subject'),
      message: formData.get('message'),
      pageId: pageId
    };

    try {
      const response = await axios.post('https://siteapi.iykons.com/api/Feedback', jsonData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        // toast.success('Details sent successfully');
      } else {
        toast.error('Failed to send Details');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  return (

    <>


      <div class="callback-form callback-services">
        <div class="container">
          <div class="row">
            <div className="col-md-2">
              {/* Image Column */}
              <img src={headerLog} alt="header logo" className="images" />

            </div>
            <div className="col-md-10">
              <div class="section-heading">

                <span>LET'S TALK</span>
                <h2>Request a Free Quote</h2>
              </div>
            </div>

            <div class="contact-form">
              <form
                id="contact" action="" method="post"
                ref={form} onSubmit={sendEmail}
                role="form"
                className="php-email-form">
                <div class="row">
                  <div class="col-lg-4 col-md-12 col-sm-12">
                    <fieldset>
                      <input
                        name="name"
                        type="text"
                        class="form-control"
                        id="name"
                        placeholder="Full Name"
                        required=""
                      />
                    </fieldset>
                  </div>
                  <div class="col-lg-4 col-md-12 col-sm-12">
                    <fieldset>
                      <input
                        name="email"
                        type="text"
                        class="form-control"
                        id="email"
                        pattern="[^ @]*@[^ @]*"
                        placeholder="E-Mail Address"
                        required=""
                      />
                    </fieldset>
                  </div>
                  <div class="col-lg-4 col-md-12 col-sm-12">
                    <fieldset>
                      <input
                        name="subject"
                        type="text"
                        class="form-control"
                        id="subject"
                        placeholder="Subject"
                        required=""
                      />
                    </fieldset>
                  </div>
                  <div class="col-lg-12">
                    <fieldset>
                      <textarea
                        name="message"
                        rows="6"
                        class="form-control"
                        id="message"
                        placeholder="Your Message"
                        required=""
                      ></textarea>
                    </fieldset>
                  </div>
                  <div class="col-lg-12">
                    <fieldset>
                      <button
                        type="submit"
                        id="form-submit"
                        class="border-button"
                      >
                        Send Message
                      </button>
                    </fieldset>
                  </div>
                  <div class="col-lg-12">
                    <p className="formtext">
                      Submitting your details indicates your acceptance that
                      IYKONS will process your personal data in line with
                      UK-GDPR. Normally you could expect a reply from IYKONS
                      within two business days.
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>


    </>
  );
}
